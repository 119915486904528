import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { MessageServiceService } from 'src/app/services/message-service.service';

@Component({
  selector: 'app-landing',
  templateUrl: './landing.component.html',
  styleUrls: ['./landing.component.css']
})
export class LandingComponent implements OnInit
{

  searchText = new FormControl();

  constructor(private router: Router, private messageService: MessageServiceService)
  {
    this.searchText.setValue('');
  }

  ngOnInit()
  {
  }

  search()
  {
    // this.router.navigateByUrl('/searchResults/' + this.searchText.value);
    this.router.navigate(['/searchResults'], { queryParams: { key: this.searchText.value, fetch: true } });
    setTimeout(() =>
    {
      this.messageService.filterChain('ALL');
    }, 2000);
  }

  filterChain(chain)
  {
    // this.router.navigateByUrl('/searchResults/' + this.searchText.value);
    this.router.navigate(['/searchResults'], { queryParams: { key: this.searchText.value, fetch: true } });
    setTimeout(() =>
    {
      this.messageService.filterChain(chain);
    }, 2000);
  }

  filterCategory(category)
  {
    this.search();
    setTimeout(() =>
    {
      this.messageService.filterCategory(category);
    }, 2000);
  }

}
