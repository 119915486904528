import { Injectable, NgZone } from '@angular/core';
import { AngularFirestore } from '@angular/fire/firestore';
import { Router } from '@angular/router';
import { AuthService } from './auth.service';

@Injectable({
  providedIn: 'root'
})
export class DbService
{

  favCollection = '';
  favs: any = [];
  favIds: any = [];

  constructor(
    private firestore: AngularFirestore,
    private authService: AuthService,
    private zone: NgZone,
    private router: Router,
  )
  {
    this.authService.loginChange.subscribe((user) =>
    {
      // console.log(user);
      if (user === null) this.favCollection = null;
      else
      {
        this.favCollection = 'fav-' + user.email
        // this.getFavs();
      }
    })
  }

  addToFavs(record, id)
  {
    console.log(this.favCollection);
    record['FireStoreDocId'] = id;
    this.firestore.collection(this.favCollection).doc(id).set(record).then(
      result =>
      {
        alert('Added to Favorites. Navigate to dashboard to view.')
        // this.getFavs();
      }
    ).catch((error) =>
    {
      console.log(error);
    });
  }

  getFavs()
  {
    this.firestore.collection(this.favCollection).get().toPromise().then((querySnapshot) =>
    {
      this.favs = [];
      this.favs = querySnapshot.docs.map((doc) =>
      {
        return { id: doc.id, ...doc.data() }
      })
      console.log(this.favs);
      this.favIds = [];
      this.favs.forEach(fav =>
      {
        this.favIds.push(fav.FireStoreDocId);
      });
      console.log(this.favIds);
    })
  }

  removeFromFavs(record_id)
  {
    console.log(record_id);
    this.firestore.doc(this.favCollection + '/' + record_id).delete();
    // // this.zone.run(() => {
    //   var index = this.favIds.indexOf(record_id);
    //   this.favs.splice(index);
    //   this.favIds.splice(index);
    // // });
    this.router.navigateByUrl('/', { skipLocationChange: true }).then(() =>
    {
      // this.router.navigate(['Your actualComponent']);
      this.router.navigateByUrl('/dashboard');
    });
  }

}
