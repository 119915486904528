import { Injectable, NgZone } from '@angular/core';
import { auth } from 'firebase/app';
import { Router } from "@angular/router";
import { AngularFireAuth } from "@angular/fire/auth";
import { Observable, Subject } from 'rxjs';

import * as waxjs from "@waxio/waxjs/dist";

// import Web3 from "web3";
// import Web3Modal from "web3modal";
// import WalletConnectProvider from "@walletconnect/web3-provider";

import ScatterJS from '@scatterjs/core';
import ScatterEOS from '@scatterjs/eosjs2';
import { JsonRpc, Api } from 'eosjs';

import { User } from '../interfaces/user';
import { SpinnerOverlayService } from './spinner-overlay.service';
import { DbService } from './db.service';

@Injectable({
  providedIn: 'root'
})

export class AuthService
{
  currentUser = null;
  loginChange: Subject<any> = new Subject<any>();

  user: User;
  userData: Observable<firebase.User>;

  wax = new waxjs.WaxJS('https://chain.wax.io', null, null, false);

  private web3js: any;
  private provider: any;
  private metaMaskAccounts: any;
  web3Modal

  private accountStatusSource = new Subject<any>();
  accountStatus$ = this.accountStatusSource.asObservable();

  constructor(
    public router: Router,
    public ngZone: NgZone,
    public afAuth: AngularFireAuth,
    private angularFireAuth: AngularFireAuth,
    private spinner: SpinnerOverlayService
  )
  {
    this.afAuth.authState.subscribe(user =>
    {
      this.user = user;
      // console.log(this.user);
      if (this.user !== null)
      {
        var emailSplit = this.afAuth.auth.currentUser.email.split('-delim-');
        // console.log(emailSplit);
        if (emailSplit.length === 1)
        {
          var [loginid, domain] = this.afAuth.auth.currentUser.email.split('@');
          if (domain === 'gmail.com')
          {
            this.loginChange.next({
              'type': 'google',
              'displayName': this.afAuth.auth.currentUser.displayName,
              'email': this.afAuth.auth.currentUser.email,
              'photoURL': this.afAuth.auth.currentUser.photoURL
            });
          }
        }
        else
        {
          if (emailSplit[0] === 'WAX')
          {
            // console.log(emailSplit[1].split('@')[0]);
            this.loginChange.next({
              'type': 'wax',
              'displayName': emailSplit[1].split('@')[0],
              'email': this.afAuth.auth.currentUser.email,
              'photoURL': this.afAuth.auth.currentUser.photoURL
            });
          }
        }
      }
    })
    this.loginChange.subscribe((value) =>
    {
      this.currentUser = value
    });
    this.userData = angularFireAuth.authState;

    // const providerOptions = {
    //   walletconnect: {
    //     package: WalletConnectProvider, // required
    //     options: {
    //       infuraId: "INFURA_ID" // required
    //     }
    //   }
    // };

    // this.web3Modal = new Web3Modal({
    //   network: "mainnet", // optional
    //   cacheProvider: true, // optional
    //   providerOptions, // required
    //   theme: {
    //     background: "rgb(39, 49, 56)",
    //     main: "rgb(199, 199, 199)",
    //     secondary: "rgb(136, 136, 136)",
    //     border: "rgba(195, 195, 195, 0.14)",
    //     hover: "rgb(16, 26, 32)"
    //   }
    // });
  }

  // Firebase SignInWithPopup
  OAuthProvider(provider)
  {
    return this.afAuth.auth.signInWithPopup(provider)
      .then((res) =>
      {
        this.ngZone.run(() =>
        {
          // this.router.navigate(['dashboard']);
          // console.log(this.afAuth.auth.currentUser);
          // this.router.navigate(['landing']);
        })
      }).catch((error) =>
      {
        window.alert(error)
      })
  }

  // Firebase Google Sign-in
  SigninWithGoogle()
  {
    this.spinner.show();
    this.OAuthProvider(new auth.GoogleAuthProvider())
      .then(res =>
      {
        console.log('Successfully logged in!');
        this.loginChange.next({
          'type': 'google',
          'displayName': this.afAuth.auth.currentUser.displayName,
          'email': this.afAuth.auth.currentUser.email,
          'photoURL': this.afAuth.auth.currentUser.photoURL
        });
        this.spinner.hide();
      }).catch(error =>
      {
        console.log(error)
      });
  }

  // Firebase Logout 
  SignOut()
  {
    return this.afAuth.auth.signOut().then(() =>
    {
      this.ngZone.run(() =>
      {
        console.log('Successfully logged out!');
        this.loginChange.next(null);
      })
    })
  }

  async waxLogin()
  {
    try
    {
      const userAccount = await this.wax.login();
      // console.log(userAccount);
      this.customSignup('WAX-delim-' + userAccount, userAccount);
      // this.loginChange.next({
      //   'type': 'wax',
      //   'displayName': userAccount,
      //   'photoURL': null
      // })
    } catch (e)
    {

    }
  }

  logout()
  {
    switch (this.currentUser.type)
    {
      case 'google':
        this.SignOut();
        break;
      case 'wax':
        this.loginChange.next(null);
        break;
      case 'email':
        this.SignOutOfEmail();
        this.loginChange.next(null);
        break;
      case 'metamask':
        this.loginChange.next(null);
        break;
      case null:
        this.loginChange.next(null);
        break;
    }
  }

  SignUpWithEmail(email: string, password: string)
  {
    this.spinner.show();
    this.angularFireAuth
      .auth
      .createUserWithEmailAndPassword(email, password)
      .then(res =>
      {
        this.spinner.hide();
        console.log('You are Successfully signed up!', res);
        alert('You are Successfully signed up!');
      })
      .catch(error =>
      {
        this.spinner.hide();
        console.log('Something is wrong:', error.message);
        alert(error.message);
      });
  }

  /* Sign in */
  SignInWithEmail(email: string, password: string)
  {
    this.spinner.show();
    this.angularFireAuth
      .auth
      .signInWithEmailAndPassword(email, password)
      .then(res =>
      {
        this.spinner.hide();
        console.log('You are Successfully logged in!', res);
        // alert('You are Successfully logged in!');
        this.loginChange.next({
          'type': 'email',
          'displayName': this.angularFireAuth.auth.currentUser.displayName,
          'email': this.angularFireAuth.auth.currentUser.email,
          'photoURL': null
        })
      })
      .catch(err =>
      {
        this.spinner.hide();
        console.log('Something is wrong:', err.message);
        alert(err.message);
      });
  }

  /* Sign out */
  SignOutOfEmail()
  {
    this.angularFireAuth
      .auth
      .signOut();
  }

  customSignup(username, password)
  {
    var email = username + '@dcart.com';
    this.afAuth.auth.createUserWithEmailAndPassword(email, password)
      .then((result) =>
      {
        // alert("You have been successfully registered!");
        this.customSignin(email, password);
        console.log(result.user)
      }).catch((error) =>
      {
        if (error.code.includes("auth/email-already-in-use"))
        {
          this.customSignin(username, password);
        }
        else
        {
          alert(error.message);
        }
      })
  }

  customSignin(username, password)
  {
    var email = username + '@dcart.com';
    this.afAuth.auth.signInWithEmailAndPassword(email, password)
      .then((result) =>
      {
        // console.log(result.user);
        this.loginChange.next({
          'type': 'wax',
          'displayName': username.split('-delim-')[1],
          'email': email,
          'photoURL': null
        })
      }).catch((error) =>
      {
        console.log(error);
        alert(error.message)
      })
  }

  // async metamaskLogin()
  // {
  //   this.web3Modal.clearCachedProvider();

  //   this.provider = await this.web3Modal.connect(); // set provider
  //   this.web3js = new Web3(this.provider); // create web3 instance
  //   this.metaMaskAccounts = await this.web3js.eth.getAccounts();
  //   this.loginChange.next({
  //     'type': 'metamask',
  //     'displayName': this.metaMaskAccounts[0],
  //     'photoURL': null
  //   })
  // }

  // scatterLogin()
  // {
  //   ScatterJS.plugins(new ScatterEOS());
  //   const network = ScatterJS.Network.fromJson({
  //     blockchain: 'eos',
  //     chainId: 'aca376f206b8fc25a6ed44dbdc66547c36c6c33e3a119ffbeaef943642f0e906',
  //     host: 'nodes.get-scatter.com',
  //     port: 443,
  //     protocol: 'https'
  //   });
  //   // {
  //   //   blockchain: "eos",
  //   //   protocol: "http",
  //   //   host: "jungle2.cryptolions.io",
  //   //   port: 80,
  //   //   chainId:      "e70aaab8997e1dfce58fbfac80cbbb8fecec7b99cf982a9444273cbc64c41473" 
  //   // }
  //   const rpc = new JsonRpc(network.fullhost());

  //   ScatterJS.connect('simplemarket.io', { network }).then(connected =>
  //   {
  //     console.log(connected);
  //     if (!connected) return console.error('no scatter');
  //     console.log(connected);

  //     const eos = ScatterJS.eos(network, Api, { rpc });

  //     ScatterJS.login().then(id =>
  //     {
  //       if (!id) return console.error('no identity');
  //       console.log(id);
  //     });
  //   });
  // }
}