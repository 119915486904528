import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { MatDialog, MatDialogRef } from '@angular/material';
import { AuthService } from 'src/app/services/auth.service';

@Component({
  selector: 'app-email-log-in',
  templateUrl: './email-log-in.component.html',
  styleUrls: ['./email-log-in.component.css']
})
export class EmailLogInComponent implements OnInit
{

  Loginsignup: FormGroup = new FormGroup({
    email: new FormControl('', [Validators.required, Validators.email, Validators.pattern('^[a-z0-9._%+-]+@[a-z0-9.-]+\\.[a-z]{2,4}$')]),
    password: new FormControl('', Validators.required),
  });

  hide = true;

  constructor(private authService: AuthService,
    // @Inject(MAT_DIALOG_DATA) public data: ShareDialogData,
    public dialogRef: MatDialogRef<EmailLogInComponent>,
    private dialog: MatDialog) { }

  ngOnInit()
  {
  }

  get passwordget()
  {
    return this.Loginsignup.get('password');
  }

  get emailget()
  {
    return this.Loginsignup.get('email');
  }

  login()
  {
    this.authService.SignInWithEmail(this.Loginsignup.get('email').value, this.Loginsignup.get('password').value)
    this.closeModal();
  }

  signup()
  {
    this.authService.SignUpWithEmail(this.Loginsignup.get('email').value, this.Loginsignup.get('password').value);
    this.closeModal();
  }

  closeModal()
  {
    this.dialogRef.close();
  }
}
