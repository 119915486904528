import { HttpClient, HttpHeaders, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class EthService
{

  apiRoot: string = "https://api.opensea.io/api/v1/";
  results: any;
  loading: boolean;

  constructor(private http: HttpClient) { }

  getAssets(offset: any)
  {
    let promise = new Promise((resolve, reject) =>
    {
      let apiURL = `${this.apiRoot}assets/?order_direction=desc&offset=${offset}&limit=50`;
      this.http
        .get(apiURL)
        .toPromise()
        .then(
          (res: any) =>
          {
            console.log('response ', res);
            this.results = res;
            resolve(this.results);
          },
          msg =>
          {
            console.log('error ', msg);
            reject(msg);
          }
        );
    });
    return promise;
  }

  getEvents(asset_contract_address, collection_slug, token_id, offset): Observable<HttpResponse<any>>
  {
    let apiUrl = `${this.apiRoot}events/?asset_contract_address=${asset_contract_address}&collection_slug=${collection_slug}&token_id=${token_id}&only_opensea=false&offset=${offset}&limit=50`;
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json'
      }),
      crossDomain: true,
      observe: 'response' as 'response'
    };
    return this.http.get<any>(apiUrl,
      httpOptions)
      .pipe(

      );
  }

  getAsset(asset_contract_address, token_id): Observable<HttpResponse<any>>
  {
    let apiUrl = `${this.apiRoot}asset/${asset_contract_address}/${token_id}`;
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json'
      }),
      crossDomain: true,
      observe: 'response' as 'response'
    };
    return this.http.get<any>(apiUrl,
      httpOptions)
      .pipe(

      );
  }

}
