import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-defi',
  templateUrl: './defi.component.html',
  styleUrls: ['./defi.component.css']
})
export class DefiComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
