import { NgModule, NO_ERRORS_SCHEMA } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MatButtonModule, MatFormFieldModule, MatInputModule, MatMenuModule, MatTabsModule, MatTooltipModule, MAT_FORM_FIELD_DEFAULT_OPTIONS } from '@angular/material';
import { MatIconModule } from '@angular/material/icon';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { NgxPaginationModule } from 'ngx-pagination';
import { LightboxModule } from 'ngx-lightbox';
import { MatDialogModule, MatDialogConfig, MatDialogRef } from '@angular/material/dialog';

import { LayoutRoutingModule } from './layout-routing.module';
import { LayoutComponent } from './layout.component';
import { DashboardComponent } from '../../components/dashboard/dashboard.component';
import { LandingComponent } from '../../components/landing/landing.component';
import { HeaderComponent } from '../../components/header/header.component';
import { FooterComponent } from '../../components/footer/footer.component';
import { MDataDialogComponent, SearchResultsComponent } from '../../components/search-results/search-results.component';
import { PaginationService } from '../../services/pagination.service';
import { environment } from 'src/environments/environment';
import { AngularFireAuthModule } from '@angular/fire/auth';
import { AngularFireModule } from '@angular/fire';
import { AngularFireDatabaseModule } from '@angular/fire/database';
import { AngularFirestoreModule } from '@angular/fire/firestore';

import { LogInComponent } from 'src/app/components/log-in/log-in.component';
import { EmailLogInComponent } from '../../components/email-log-in/email-log-in.component';
import { TermsAndConditionsComponent } from '../../components/terms-and-conditions/terms-and-conditions.component';
import { AssetComponent } from '../../components/asset/asset.component';
import { DefiComponent } from '../../components/defi/defi.component';

@NgModule({
  declarations: [
    LayoutComponent,
    DashboardComponent,
    LandingComponent,
    HeaderComponent,
    FooterComponent,
    SearchResultsComponent,
    MDataDialogComponent,
    LogInComponent,
    EmailLogInComponent,
    TermsAndConditionsComponent,
    AssetComponent,
    DefiComponent,
  ],
  imports: [
    CommonModule,
    MatTooltipModule,
    MatButtonModule,
    MatIconModule,
    MatFormFieldModule,
    FormsModule,
    ReactiveFormsModule,
    MatTabsModule,
    NgxPaginationModule,
    LightboxModule,
    MatDialogModule,
    AngularFireAuthModule,
    AngularFireModule.initializeApp(environment.firebaseConfig),
    AngularFirestoreModule,
    AngularFireDatabaseModule,
    FormsModule,
    ReactiveFormsModule,
    MatInputModule,
    MatMenuModule,

    LayoutRoutingModule,
  ],
  schemas: [NO_ERRORS_SCHEMA],
  providers: [
    PaginationService,
    { provide: MAT_FORM_FIELD_DEFAULT_OPTIONS, useValue: { appearance: 'fill' } }
  ],
  entryComponents: [
    MDataDialogComponent,
    EmailLogInComponent,
    LogInComponent,
  ]
})
export class LayoutModule { }
