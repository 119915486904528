import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class DataService
{

  constructor() { }

  deepCopy(obj)
  {
    var copy;
    // Handle the 3 simple types, and null or undefined
    if (null == obj || "object" != typeof obj) return obj;

    // Handle Array
    if (obj instanceof Array)
    {
      copy = [];
      for (var i = 0, len = obj.length; i < len; i++)
      {
        copy[i] = this.deepCopy(obj[i]);
      }
      return copy;
    }

    // Handle Object
    if (obj instanceof Object)
    {
      copy = {};
      for (var attr in obj)
      {
        if (obj.hasOwnProperty(attr)) copy[attr] = this.deepCopy(obj[attr]);
      }
      return copy;
    }
  }
  
}
