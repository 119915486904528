import { Component, HostListener, OnInit } from '@angular/core';
import { MatDialog, MatDialogConfig, MatDialogRef } from '@angular/material';
import { Router } from '@angular/router';
import { Lightbox } from 'ngx-lightbox';

import { ApiService } from 'src/app/services/api.service';
import { DbService } from 'src/app/services/db.service';
import { EthService } from 'src/app/services/eth.service';
import { SpinnerOverlayService } from 'src/app/services/spinner-overlay.service';
import { MDataDialogComponent } from '../search-results/search-results.component';

@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.css']
})
export class DashboardComponent implements OnInit
{
  dispEvents: boolean = false;
  dispEventsBody: boolean = false;
  curEvents: any = [];
  eventIndex: any = '';

  album: any = [];

  p: number = 1;

  constructor(
    public dbService: DbService,
    private apiService: ApiService,
    private spinnerService: SpinnerOverlayService,
    private ethService: EthService,
    private dialog: MatDialog,
    private lightbox: Lightbox,
    private router: Router,
  )
  {
    this.dbService.getFavs();
  }

  ngOnInit()
  {
  }

  viewAsset(index)
  {
    // console.log(typeof type);
    var type = this.dbService.favs[index].FireStoreDocId.split('-')[0];
    if (type === 'ETH')
    {
      this.router.navigate(['/asset/ETH/' + this.dbService.favs[index].token_id], { queryParams: { fav: true, contract_addr: this.dbService.favs[index].asset_contract.address } });
    }
    if (type === 'WAX')
    {
      this.router.navigate(['/asset/WAX/' + this.dbService.favs[index].id], { queryParams: { fav: true,  asset: JSON.stringify(this.dbService.favs[index]) } });
    }
    if (type === 'EOS')
    {
      this.router.navigate(['/asset/EOS/' + this.dbService.favs[index].id], { queryParams: { fav: true,  asset: JSON.stringify(this.dbService.favs[index]) } });
    }
  }

  shortName(str: String)
  {
    return str.substring(0, 15);
  }

  showHistory(i: number) //chain and asset index
  {
    this.spinnerService.show();
    this.dispEvents = false;
    this.dispEventsBody = false;
    i += (this.p - 1) * 4;
    if (this.dbService.favs[i].FireStoreDocId.includes('ETH'))
    {
      this.eventIndex = 'ETH';
      this.ethService.getEvents(
        this.dbService.favs[i].asset_contract.address,
        this.dbService.favs[i].collection.slug,
        this.dbService.favs[i].token_id,
        0
      ).subscribe(
        res =>
        {
          // console.log(res.body);
          this.curEvents = res.body.asset_events;
          this.dispEvents = true;
          this.dispEventsBody = true;
          // console.log(this.curEvents);
          this.spinnerService.hide();
        },
        err =>
        {
          this.spinnerService.hide();
          console.log(err);
        }
      )
    }
    if (this.dbService.favs[i].FireStoreDocId.includes('EOS'))
    {
      this.eventIndex = 'EOS';
      this.getEosWaxEvents(i);
    }
    if (this.dbService.favs[i].FireStoreDocId.includes('WAX'))
    {
      this.eventIndex = 'WAX';
      this.getEosWaxEvents(i);
    }
  }

  getEosWaxEvents(i)
  {
    this.apiService.getEosWaxEvents(this.dbService.favs[i].id).subscribe(
      res =>
      {
        // console.log(res.body);
        this.dispEvents = true;
        this.dispEventsBody = true;
        this.curEvents = res.body;
        // console.log(this.curWaxEvents);
        this.spinnerService.hide();
      },
      err =>
      {
        console.log(err);
        this.spinnerService.hide();
      }
    )
  }

  openMData(mdata)
  {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.autoFocus = true;
    dialogConfig.width = 'auto';
    dialogConfig.height = 'auto';
    dialogConfig.disableClose = false;
    dialogConfig.data = { 'mdata': JSON.parse(mdata) };
    const dialogRef = this.dialog.open(MDataDialogComponent, dialogConfig);
    dialogRef.afterClosed().subscribe(
      val =>
      {
        console.log("Dialog output:", val);
      }
    );
  }

  openAlbum(chain, i: number)
  {
    i += (this.p - 1) * 4;
    this.album = [];
    var albumToPush: any = {};
    switch (chain)
    {
      case "ETH":
        albumToPush.src = this.dbService.favs[i].image_url;
        albumToPush.caption = this.dbService.favs[i].name;
        albumToPush.thumb = this.dbService.favs[i].image_thumbnail_url;
        break;
      case "EOS": case "WAX":
        albumToPush.src = this.dbService.favs[i].mdata.img;
        albumToPush.caption = this.dbService.favs[i].mdata.id;
        albumToPush.thumb = this.dbService.favs[i].mdata.img;
        break;
    }
    this.album.push(albumToPush);
    this.lightbox.open(this.album, 0);
  }

  buy()
  {
    alert('Coming Soon!');
  }

}
