import { Component, OnInit } from '@angular/core';
import { MatDialog, MatDialogConfig, MatDialogRef } from '@angular/material';

import { AuthService } from 'src/app/services/auth.service';
import { EmailLogInComponent } from '../email-log-in/email-log-in.component';
@Component({
  selector: 'app-log-in',
  templateUrl: './log-in.component.html',
  styleUrls: ['./log-in.component.css']
})

export class LogInComponent implements OnInit
{
  EmailLoginDialogRef: MatDialogRef<EmailLogInComponent>;

  constructor(public authService: AuthService,
    // @Inject(MAT_DIALOG_DATA) public data: ShareDialogData,
    public dialogRef: MatDialogRef<LogInComponent>,
    private dialog: MatDialog)
  {
    this.authService.loginChange.subscribe((value) =>
    {
      // if(this.authService.currentUser !== null)
      this.closeModal();
    })
  }

  ngOnInit() { }

  closeModal()
  {
    this.dialogRef.close();
  }

  emailLogin()
  {

    const dialogConfig = new MatDialogConfig();
    dialogConfig.autoFocus = true;
    dialogConfig.width = 'auto';
    dialogConfig.height = 'auto';
    dialogConfig.disableClose = false;
    dialogConfig.hasBackdrop = true;
    this.EmailLoginDialogRef = this.dialog.open(EmailLogInComponent, dialogConfig);
    this.EmailLoginDialogRef.afterClosed().subscribe(
      val =>
      {
        this.dialogRef.close();
        console.log("Dialog output:", val);
      }
    );
  }
}