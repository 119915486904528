import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { FormControl } from '@angular/forms';
import { NavigationEnd, Router } from '@angular/router';
import { MessageServiceService } from 'src/app/services/message-service.service';

import { MatDialog, MatDialogConfig } from '@angular/material';
import { LogInComponent } from '../log-in/log-in.component';
import { AuthService } from 'src/app/services/auth.service';
import { DbService } from 'src/app/services/db.service';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.css']
})
export class HeaderComponent implements OnInit
{

  @ViewChild('profile', { static: false }) public myProfileRef: ElementRef;

  searchText = new FormControl();

  clientWidth;
  currentUrl;

  backBtn = false;

  constructor(private router: Router,
    private messageService: MessageServiceService,
    private dialog: MatDialog,
    public authService: AuthService,
    public dbService: DbService
  )
  {
    this.clientWidth = window.innerWidth - 105;

    this.messageService.listenSearch().subscribe((searchText: any) =>
    {
      console.log(searchText);
      this.searchText.setValue(searchText);
    });

    this.backBtn = false;

    this.router.events.subscribe((_: NavigationEnd) => { 
      this.currentUrl = _.url;
      var re = /^\/asset\/*/;
      if (re.test(this.currentUrl)) {
        if(!this.currentUrl.includes('fav')) {
          this.backBtn = true;
        }
      }
      else {
        this.backBtn = false;
      }
    });
  }

  ngOnInit()
  {
  }

  goBack()
  {
    var state = JSON.parse(localStorage.getItem('state'));
    this.router.navigate(['/searchResults'], { queryParams: { key: state.searchkey, prev: 'true' } })
  }

  search()
  {
    this.router.navigate(['/searchResults'], { queryParams: { key: this.searchText.value } });
    setTimeout(() =>
    {
      this.messageService.filterChain('ALL');
    }, 2000);
  }

  login()
  {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.autoFocus = true;
    dialogConfig.width = 'auto';
    dialogConfig.height = 'auto';
    dialogConfig.disableClose = false;
    // dialogConfig.data = { 'mdata': JSON.parse(mdata) };
    const dialogRef = this.dialog.open(LogInComponent, dialogConfig);
    dialogRef.afterClosed().subscribe(
      val =>
      {
        console.log("Dialog output:", val);
      }
    );
  }
}
