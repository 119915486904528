import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { AssetComponent } from 'src/app/components/asset/asset.component';
import { DashboardComponent } from 'src/app/components/dashboard/dashboard.component';
import { DefiComponent } from 'src/app/components/defi/defi.component';
import { LandingComponent } from 'src/app/components/landing/landing.component';
import { LogInComponent } from 'src/app/components/log-in/log-in.component';
import { SearchResultsComponent } from 'src/app/components/search-results/search-results.component';
import { TermsAndConditionsComponent } from 'src/app/components/terms-and-conditions/terms-and-conditions.component';


const routes: Routes = [
  { path: '', redirectTo: 'landing', pathMatch: 'full' },
  { path: 'landing', component: LandingComponent },
  { path: 'login', component: LogInComponent },
  { path: 'dashboard', component: DashboardComponent },
  { path: 'searchResults', component: SearchResultsComponent },
  { path: 'terms-and-conditions', component: TermsAndConditionsComponent },
  { path: 'asset/:type/:id', component: AssetComponent },
  { path: 'DeFi', component: DefiComponent },
  { path: '**', redirectTo: 'landing' },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class LayoutRoutingModule { }
