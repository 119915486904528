import { Component, Input, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Location } from '@angular/common';
import { MatDialog, MatDialogConfig } from '@angular/material';

import { EthService } from 'src/app/services/eth.service';
import { SpinnerOverlayService } from 'src/app/services/spinner-overlay.service';
import { ApiService } from 'src/app/services/api.service';
import { MDataDialogComponent } from 'src/app/components/search-results/search-results.component';

@Component({
  selector: 'app-asset',
  templateUrl: './asset.component.html',
  styleUrls: ['./asset.component.css']
})
export class AssetComponent implements OnInit
{
  state: any;

  assetType: any;
  assetId: any;
  assetContractAddr: any
  asset: any;

  chainInfo: boolean = true;

  events: any;
  dispEth: boolean = false;
  dispWax: boolean = false;

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private location: Location,
    private dialog: MatDialog,
    private ethService: EthService,
    private apiService: ApiService,
    private spinnerService: SpinnerOverlayService,
  )
  {
    this.assetType = this.route.snapshot.paramMap.get('type');
    this.assetId = this.route.snapshot.paramMap.get('id');

    this.route.queryParams.subscribe(params =>
    {
      if (this.assetType === 'ETH')
      {
        this.assetContractAddr = params['contract_addr'];
      }
      else
      {
        this.asset = JSON.parse(params['asset']);
      }
    });

    this.fetchAsset();
  }

  ngOnInit()
  {
  }

  convertEth(cur_price) {
    console.log(cur_price/1000000000000000000);
    return cur_price/1000000000000000000;
  }

  getKeys(jsonObj)
  {
    return Object.keys(jsonObj);
  }

  shortName(str: String)
  {
    return str.substring(0, 15);
  }

  openMData(mdata)
  {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.autoFocus = true;
    dialogConfig.width = 'auto';
    dialogConfig.height = 'auto';
    dialogConfig.disableClose = false;
    dialogConfig.data = { 'mdata': JSON.parse(mdata) };
    const dialogRef = this.dialog.open(MDataDialogComponent, dialogConfig);
    dialogRef.afterClosed().subscribe(
      val =>
      {
        console.log("Dialog output:", val);
      }
    );
  }

  fetchAsset()
  {
    this.spinnerService.show();
    this.dispEth = false;
    this.dispWax = false;
    switch (this.assetType)
    {
      case 'ETH':
        this.ethService.getAsset(this.assetContractAddr, this.assetId).subscribe(
          res =>
          {
            // console.log(res);
            this.asset = res.body;
            this.ethService.getEvents(
              this.asset.asset_contract.address,
              this.asset.collection.slug,
              this.asset.token_id,
              0
            ).subscribe(
              res =>
              {
                // console.log(res.body);
                this.events = res.body.asset_events;
                this.dispEth = true;
                this.spinnerService.hide();
              },
              err =>
              {
                this.spinnerService.hide();
                console.log(err);
              }
            )
            // this.spinnerService.hide();
          },
          err =>
          {
            console.log(err);
            this.spinnerService.hide();
          }
        )
        break;
      case 'EOS': case 'WAX':
        this.apiService.getEosWaxEvents(this.asset.id).subscribe(
          res =>
          {
            this.dispWax = true;
            this.events = res.body;
            console.log(this.events);
            this.spinnerService.hide()
          },
          err =>
          {
            console.log(err);
            this.spinnerService.hide();
          }
        )
        break;
    }
  }
}
