import { HttpClient, HttpHeaders, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class ApiService {

  constructor(private http: HttpClient) { }


  getAssets(offset: any): Observable<HttpResponse<any>> {
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json'
      }),
      crossDomain: true,
      observe: 'response' as 'response'
    };
    return this.http.get<any>('https://api.opensea.io/api/v1/assets?order_direction=desc&offset=' + offset + '&limit=10000',
      httpOptions)
      .pipe(

      );
  }

  getEosWaxEvents(id: any):  Observable<HttpResponse<any>> {
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json'
      }),
      crossDomain: true,
      observe: 'response' as 'response'
    };
    return this.http.get<any>('https://wax.simplemarket.io/api/v1/asset/logs?assetId=' + id + '&skip=0&limit=100',
      httpOptions)
      .pipe(

      );
  }

}
