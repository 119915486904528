import { Component, ElementRef, HostListener, Inject, Input, NgZone, OnInit, QueryList, ViewChildren } from '@angular/core';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { ApiService } from 'src/app/services/api.service';
import { MessageServiceService } from 'src/app/services/message-service.service';
import { SpinnerOverlayService } from 'src/app/services/spinner-overlay.service';
import { DoCheck, KeyValueDiffers, KeyValueDiffer } from '@angular/core';


import { Api, JsonRpc, RpcError } from 'eosjs';
import { JsSignatureProvider } from 'eosjs/dist/eosjs-jssig';

import * as waxjs from "@waxio/waxjs/dist";
import { EthService } from 'src/app/services/eth.service';
import { Lightbox } from 'ngx-lightbox';
import { MatDialog, MatDialogConfig, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { DataService } from 'src/app/services/data.service';
import { DbService } from 'src/app/services/db.service';
import { AuthService } from 'src/app/services/auth.service';
import { DOCUMENT } from '@angular/common';

export interface MDataDialogData
{
  mdata: any;
}

@Component({
  selector: 'app-search-results',
  templateUrl: './search-results.component.html',
  styleUrls: ['./search-results.component.css']
})
export class SearchResultsComponent implements OnInit
{
  private dom: Document;

  @ViewChildren('searchDiv') searchChildren: QueryList<any>;

  // DONT CHANGE ORDER AT ALL
  assetArray: any = [
    { chain: "EOS", contract: "simpleassets", assets: [] },
    { chain: "WAX", contract: "simpleassets", assets: [] },
    { chain: "ETH", contract: "opensea", assets: [] }
  ]

  completeAssetArray: any = [];

  p0: number = 1;
  p1: number = 1;
  p2: number = 1; //pagination

  chain: any;

  showAll: boolean = true;
  showEos: boolean = false;
  showWax: boolean = false;
  showEth: boolean = false;

  differ: any;
  fetched = {
    fetchCounter: 0
  }
  offset;
  maxOffset;
  ethFethed: boolean = false;

  dispEthEvents = false;
  dispEthEventsBody = false;
  curEthEvents: any = [];
  dispEosEvents = false;
  dispEosEventsBody = false;
  curEosEvents: any = [];
  dispWaxEvents = false;
  dispWaxEventsBody = false;
  curWaxEvents: any = [];

  album: any = [];

  searchText: any;

  constructor(private apiService: ApiService,
    private spinnerService: SpinnerOverlayService,
    private router: Router,
    private route: ActivatedRoute,
    private messageService: MessageServiceService,
    private ethService: EthService,
    private differs: KeyValueDiffers,
    private lightbox: Lightbox,
    private dialog: MatDialog,
    private dataService: DataService,
    private zone: NgZone,
    private authService: AuthService,
    private dbService: DbService,
    @Inject(DOCUMENT) dom: Document,
  )
  {
    this.dom = dom;

    this.messageService.listenChain().subscribe((chain: any) =>
    {
      // console.log(chain);
      this.chain = chain;
      this.filterChain(chain);
    });

    this.messageService.listenCategory().subscribe((category: any) =>
    {
      console.log(category);
    })

    this.differ = this.differs.find(this.fetched).create();

    this.offset = 0;
    this.maxOffset = 200;
  }

  ngDoCheck()
  {
    const change = this.differ.diff(this.fetched);
    if (change)
    {
      change.forEachChangedItem(item =>
      {
        // console.log('item changed', item);
        this.ethService.results.assets.filter(
          ethAsset =>
          {
            if (ethAsset.name !== null
              && ethAsset.owner.user !== null
              // && ethAsset.image_thumbnail_url !== null
            )
            {
              return ethAsset;
            }
          }
        ).map(
          ethAsset =>
          {
            this.zone.run(() =>
            {
              this.assetArray[2].assets.push(ethAsset);
            })
          }
        )

        this.offset += 50;
        if (this.offset < this.maxOffset)
        {
          this.fetchEthAssets(this.offset);
        }
        else
        {
          this.ethFethed = true;
          // this.spinnerService.hide();
          this.completeAssetArray = this.dataService.deepCopy(this.assetArray);
          this.filterAssets();
        }
      });
    }
  }

  ngOnInit()
  {
    this.spinnerService.show();
    this.route.queryParams.subscribe(params =>
    {
      if (params['prev'] !== null && params['prev'] !== undefined && params['prev'] === 'true')
      {
        this.resetChain();
        var state: any = JSON.parse(localStorage.getItem('state'));
        this.p2 = Number(state.eth_page);
        this.p1 = Number(state.wax_page);
        this.p0 = Number(state.eos_page);
        this.searchText = state.searchKey;
        this.messageService.filterSearch(this.searchText);
        this.assetArray = state.searchResults;
        this.completeAssetArray = state.completeAssetArray;
        this.ethFethed = true;
        setTimeout(() =>
        {
          this.messageService.filterChain(state.chain);
        }, 1000);
        this.spinnerService.hide();
      }
      else
      {
        this.searchText = params['key'];
        this.messageService.filterSearch(this.searchText);
        if (params['fetch'] !== null && params['fetch'] !== undefined && params['fetch'] === 'true')
        {
          this.retrieveAssets();
        }
        else
        {
          this.filterAssets();
        }
      }
    });
  }

  viewAsset(type, index)
  {
    var state = {
      'eth_page': String(this.p2),
      'wax_page': String(this.p1),
      'eos_page': String(this.p0),
      'searchKey': this.searchText,
      'searchResults': this.assetArray,
      'completeAssetArray': this.completeAssetArray
    }
    if (this.chain === '' || this.chain === undefined || this.chain === null)
      state['chain'] = 'ALL';
    else
      state['chain'] = this.chain;
    localStorage.setItem('state', JSON.stringify(state));

    console.log(typeof type);
    if (type === 2)
    {
      index += (this.p2 - 1) * 4;
      this.router.navigate(['/asset/ETH/' + this.assetArray[type].assets[index].token_id], { queryParams: { contract_addr: this.assetArray[type].assets[index].asset_contract.address } });
    }
    if (type === 1)
    {
      index += (this.p1 - 1) * 4;
      this.router.navigate(['/asset/WAX/' + this.assetArray[type].assets[index].id], { queryParams: { asset: JSON.stringify(this.assetArray[type].assets[index]) } });
    }
    if (type === 0)
    {
      index += (this.p0 - 1) * 4;
      this.router.navigate(['/asset/EOS/' + this.assetArray[type].assets[index].id], { queryParams: { asset: JSON.stringify(this.assetArray[type].assets[index]) } });
    }
  }

  copy(id)
  {
    // console.log(id);
    // try {
    //   window.getSelection().selectAllChildren(this.dom.getElementById(id));
    //   this.dom.execCommand("copy");
    // }
    // finally {
    //   this.dom.getSelection().removeAllRanges;
    // }
    const selBox = this.dom.createElement('textarea');
    selBox.style.position = 'fixed';
    selBox.style.left = '0';
    selBox.style.top = '0';
    selBox.style.opacity = '0';
    selBox.value = id;
    document.body.appendChild(selBox);
    selBox.focus();
    selBox.select();
    document.execCommand('copy');
    document.body.removeChild(selBox);
  }

  addFav(chInd, id, asInd)
  {
    if (this.authService.currentUser === null)
    {
      alert('Login to add');
    }
    else
    {
      switch (chInd)
      {
        case 2:
          asInd += (this.p2 - 1) * 4;
          break;
        case 1:
          asInd += (this.p1 - 1) * 4;
          break;
        case 0:
          asInd += (this.p0 - 1) * 4;
          break;
      }
      this.dbService.addToFavs(this.assetArray[chInd].assets[asInd], id);
    }
  }

  buy()
  {
    alert('Coming Soon!');
  }

  filterAssets()
  {
    if (this.searchText !== '' && this.searchText !== 'secretKeyToFetchAll')
    {
      this.p0 = 1;
      this.p1 = 1;
      this.p2 = 1;
      console.log(this.searchText);
      let filteredEosAssets = [];
      let filteredWaxAssets = [];
      let filteredEthAssets = []
      this.completeAssetArray[0].assets.forEach(asset =>
      {
        if (
          String(asset.id).toLowerCase().includes(String(this.searchText).toLowerCase())
          || String(asset.owner).toLowerCase().includes(String(this.searchText).toLowerCase())
          || String(asset.author).toLowerCase().includes(String(this.searchText).toLowerCase())
          || String(asset.mdata.name).toLowerCase().includes(String(this.searchText).toLowerCase())
        )
          filteredEosAssets.push(asset);
      });
      this.completeAssetArray[1].assets.forEach(asset =>
      {
        if (
          String(asset.id).toLowerCase().includes(String(this.searchText).toLowerCase())
          || String(asset.owner).toLowerCase().includes(String(this.searchText).toLowerCase())
          || String(asset.author).toLowerCase().includes(String(this.searchText).toLowerCase())
          || String(asset.mdata.name).toLowerCase().includes(String(this.searchText).toLowerCase())
        )
          filteredWaxAssets.push(asset);
      });
      this.completeAssetArray[2].assets.forEach(asset =>
      {
        var found = false;
        if (String(asset.token_id).toLowerCase().includes(String(this.searchText).toLowerCase())) found = true;
        if (String(asset.name).toLowerCase().includes(String(this.searchText).toLowerCase())) found = true;
        if (asset.owner !== null && asset.owner.user !== null && asset.owner.user.username !== null)
          if (String(asset.owner.user.username).toLowerCase().includes(String(this.searchText).toLowerCase())) found = true;
        if (asset.creator !== null && asset.creator.user !== null && asset.creator.user.username !== null)
          if (String(asset.creator.user.username).toLowerCase().includes(String(this.searchText).toLowerCase())) found = true;
        if (found)
          filteredEthAssets.push(asset);
      });
      this.zone.run(() =>
      {
        this.assetArray[0].assets = filteredEosAssets;
        this.assetArray[1].assets = filteredWaxAssets;
        this.assetArray[2].assets = filteredEthAssets;
      })
      console.log(this.assetArray);
    }
    this.spinnerService.hide();
  }

  resetHistoryDisp()
  {
    this.dispEthEvents = false;
    this.dispEthEventsBody = false;
    this.curEthEvents = [];
    this.dispEosEvents = false;
    this.dispEosEventsBody = false;
    this.curEosEvents = [];
    this.dispWaxEvents = false;
    this.dispWaxEventsBody = false;
    this.curWaxEvents = [];
  }

  showHistory(chInd, asInd: number) //chain and asset index
  {
    this.spinnerService.show();
    this.resetHistoryDisp();
    switch (chInd)
    {
      case 2:
        asInd += (this.p2 - 1) * 4;
        this.ethService.getEvents(
          this.assetArray[chInd].assets[asInd].asset_contract.address,
          this.assetArray[chInd].assets[asInd].collection.slug,
          this.assetArray[chInd].assets[asInd].token_id,
          0
        ).subscribe(
          res =>
          {
            // console.log(res.body);
            this.curEthEvents = res.body.asset_events;
            this.dispEthEvents = true;
            this.dispEthEventsBody = true;
            // console.log(this.curEthEvents);
            this.spinnerService.hide();
          },
          err =>
          {
            this.spinnerService.hide();
            console.log(err);
          }
        )
        break;
      case 1:
        asInd += (this.p1 - 1) * 4;
        this.apiService.getEosWaxEvents(this.assetArray[chInd].assets[asInd].id).subscribe(
          res =>
          {
            // console.log(res.body);
            this.dispWaxEvents = true;
            this.dispWaxEventsBody = true;
            this.curWaxEvents = res.body;
            // console.log(this.curWaxEvents);
            this.spinnerService.hide();
          },
          err =>
          {
            console.log(err);
            this.spinnerService.hide();
          }
        )
        break;
      case 0:
        asInd += (this.p0 - 1) * 4;
        this.apiService.getEosWaxEvents(this.assetArray[chInd].assets[asInd].id).subscribe(
          res =>
          {
            // console.log(res.body);
            this.dispEosEvents = true;
            this.dispEosEventsBody = true;
            this.curEosEvents = res.body;
            // console.log(this.curEosEvents);
            this.spinnerService.hide();
          },
          err =>
          {
            console.log(err);
            this.spinnerService.hide();
          }
        )
        break;
    }
  }

  openAlbum(chain, i: number)
  {
    this.album = [];
    var albumToPush: any = {};
    switch (chain)
    {
      case "ETH":
        i += (this.p2 - 1) * 4;
        albumToPush.src = this.assetArray[2].assets[i].image_url;
        albumToPush.caption = this.assetArray[2].assets[i].name;
        albumToPush.thumb = this.assetArray[2].assets[i].image_thumbnail_url;
        break;
      case "EOS":
        i += (this.p0 - 1) * 4;
        albumToPush.src = this.assetArray[0].assets[i].mdata.img;
        albumToPush.caption = this.assetArray[0].assets[i].mdata.id;
        albumToPush.thumb = this.assetArray[0].assets[i].mdata.img;
        break;
      case "WAX":
        i += (this.p1 - 1) * 4;
        albumToPush.src = this.assetArray[1].assets[i].mdata.img;
        albumToPush.caption = this.assetArray[1].assets[i].mdata.id;
        albumToPush.thumb = this.assetArray[1].assets[i].mdata.img;
        break;
    }
    this.album.push(albumToPush);
    this.lightbox.open(this.album, 0);
  }

  resetChain()
  {
    this.showAll = false;
    this.showEos = false;
    this.showWax = false;
    this.showEth = false;
  }

  filterChain(chain)
  {
    this.resetChain();
    switch (chain)
    {
      case 'ALL': this.showAll = true;
        break;
      case "EOS": this.showEos = true;
        break;
      case "WAX": this.showWax = true;
        break;
      case "ETH": this.showEth = true;
        break;
    }
  }

  getIndex(chain: string)
  {
    // console.log(chain);
    var index = -1;
    this.assetArray.find(function (item, i)
    {
      if (item.chain === chain)
      {
        index = i;
      }
    });
    return index;
  }

  shortName(str: String)
  {
    return str.substring(0, 15);
  }

  shortLink(str: String)
  {
    return str.substring(0, 25);
  }

  shortContent(str: String)
  {
    return str.substring(0, 160);
  }

  fetchEthAssets(offset): any
  {
    this.ethService.getAssets(offset).then(_ => (this.fetched.fetchCounter += 1));
  }

  retrieveAssets()
  {

    // this.fetchEthAssets(this.offset);

    const defaultPrivateKey = "5JtUScZK2XEp3g9gh7F8bwtPTRAkASmNrrftmx4AxDKD5K4zDnr"; // bob
    const signatureProvider = new JsSignatureProvider([defaultPrivateKey]);

    const rpc = new JsonRpc('https://api.eosn.io');

    const api = new Api({ rpc, signatureProvider, textDecoder: new TextDecoder(), textEncoder: new TextEncoder() });

    (async () =>
    {
      const result = await rpc.get_table_rows(
        {
          "code": "simpleassets",
          "table": "sassets",
          "scope": "111111aaabbb",
          "index_position": "0",
          "encode_type": "UTF-8",
          "lower_bound": "",
          "upper_bound": "",
          "limit": 10000,
          "reverse": false,
          "show_payer": false
        }
      );
      this.zone.run(() =>
      {
        this.assetArray[0].assets = result.rows;
        this.assetArray[0].assets.map(
          eosAsset =>
          {
            if (eosAsset.idata !== "") eosAsset.idata = JSON.parse(eosAsset.idata);
            if (eosAsset.mdata !== "") eosAsset.mdata = JSON.parse(eosAsset.mdata);
          }
        )
      })
    })();

    const wax = new waxjs.WaxJS('https://wax.greymass.com', 'user1', ['EOS7rC8jFvFrPYDqp3Nh3HdRfL79h11B1JhPEXy85enF5wwYzF3Hk']);

    (async () =>
    {
      const result = await wax.api.rpc.get_table_rows(
        {
          "code": "simpleassets",
          "table": "sassets",
          "scope": "simpleassets",
          "index_position": "0",
          "encode_type": "UTF-8",
          "lower_bound": "",
          "upper_bound": "",
          "limit": 10000,
          "reverse": false,
          "show_payer": false
        }
      );
      // console.log(result);
      this.zone.run(() =>
      {
        this.assetArray[1].assets = result.rows;
        this.assetArray[1].assets.map(
          waxAsset =>
          {
            if (waxAsset.idata !== "") waxAsset.idata = JSON.parse(waxAsset.idata);
            if (waxAsset.mdata !== "") waxAsset.mdata = JSON.parse(waxAsset.mdata);
          }
        )
      })
    })();

    this.spinnerService.hide();
    console.log(this.assetArray);
  }

  goToLink(url: string)
  {
    window.open(url, "_blank");
  }

  openMData(mdata)
  {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.autoFocus = true;
    dialogConfig.width = 'auto';
    dialogConfig.height = 'auto';
    dialogConfig.disableClose = false;
    dialogConfig.data = { 'mdata': JSON.parse(mdata) };
    const dialogRef = this.dialog.open(MDataDialogComponent, dialogConfig);
    dialogRef.afterClosed().subscribe(
      val =>
      {
        console.log("Dialog output:", val);
      }
    );
  }
}

@Component({
  selector: 'app-mdata-dialog',
  template: '<pre>{{this.data.mdata | json}}<pre>'
})

export class MDataDialogComponent implements OnInit
{

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: MDataDialogData,
    public dialogRef: MatDialogRef<MDataDialogComponent>,
    private dialog: MatDialog) { }

  ngOnInit() { }

  closeModal(): void
  {
    this.dialogRef.close();
  }

}
