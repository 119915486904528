import { Injectable } from '@angular/core';
import { Observable, Subject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class MessageServiceService {

  private _searchListner = new Subject<any>();
  private _chainListner = new Subject<any>();
  private _categoryListner = new Subject<any>();

  constructor() { }

  listenSearch(): Observable<any> {
    return this._searchListner.asObservable();
  }

  filterSearch(filterBy: string) {
    this._searchListner.next(filterBy);
  }

  listenChain(): Observable<any> {
    return this._chainListner.asObservable();
  }

  filterChain(filterBy: string) {
    this._chainListner.next(filterBy);
  }
  
  listenCategory(): Observable<any> {
    return this._categoryListner.asObservable();
  }

  filterCategory(filterBy: string) {
    this._categoryListner.next(filterBy);
  }
  
}
