import { Component, OnInit } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { MessageServiceService } from 'src/app/services/message-service.service';

@Component({
  selector: 'app-layout',
  templateUrl: './layout.component.html',
  styleUrls: ['./layout.component.css']
})
export class LayoutComponent implements OnInit
{

  clientHeight: number;
  currentUrl;

  chainTabs = [
    'ALL',
    'EOS',
    'WAX',
    'ETH'
  ];
  selectedChainIndex = 0;

  categoryTabs = [
    'all',
    'art',
    'gaming',
    'cards',
    'realty',
    'sports',
    'movies',
    'twitter'
  ];
  selectedCategoryIndex = 0;

  constructor(private router: Router, private messageService: MessageServiceService)
  {
    this.clientHeight = window.innerHeight - 90;
    this.router.events.subscribe((_: NavigationEnd) => this.currentUrl = _.url);
    this.messageService.listenChain().subscribe((chain: any) => {
      this.selectedChainIndex = this.chainTabs.indexOf(chain);
    });
    this.messageService.listenCategory().subscribe((category: any) => {
      this.selectedCategoryIndex = this.categoryTabs.indexOf(category);
    });
  }

  ngOnInit()
  {
  }

  changeChainTab($event)
  {
    this.messageService.filterChain(this.chainTabs[$event.index]);
  }

  changeCategoryTab($event)
  {
    this.messageService.filterCategory(this.categoryTabs[$event.index]);
  }
}
